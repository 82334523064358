<template>
  <div>
    <RowItem
      first
      class="row-item"
      :title="$t('feed_in_system_details.request_date')"
      :editable="false"
    >
      {{ presentableFeedInSystem.requestDate }}
    </RowItem>
    <!-- Auftragsnummer -->
    <RowItem
      class="row-item"
      :title="$t('feed_in_system_details.order_number')"
      :editable="false"
    >
      {{ feedInSystem.case_number }}
    </RowItem>
    <!-- Einspeiseadresse -->
    <RowItem
      class="row-item"
      field="updatePVInstallationAddress"
      :title="$t('feed_in_system_details.feed_in_address')"
      :editable="true"
      @edit="
        $emit('edit-component', {
          component: 'PhotovoltaicInstallationAddressComponent',
          location: 'ContractDetails',
          properties: feedInSystem,
        })
      "
    >
      <div v-html="presentableFeedInSystem.address"></div>
    </RowItem>
    <!-- Kontaktdaten -->
    <RowItem
      class="row-item"
      field="updatePVContactData"
      :title="$t('feed_in_system_details.contact_data')"
      :editable="true"
      @edit="
        $emit('edit-component', {
          component: 'ContractContactComponent',
          location: 'ContractDetails',
          properties: feedInSystem,
        })
      "
    >
      <div v-html="presentableFeedInSystem.contactData"></div>
    </RowItem>
    <!-- abweichender Rechnungsempfänger -->
    <RowItem
      class="row-item"
      field="updatePVAlternateInvoiceRecipient"
      :title="$t('feed_in_system_details.alternate_invoice_recipient')"
      :editable="
        !disqualifiedStatusesForAlternateRecipientAndAlternateDeliveryAddress.includes(
          this.feedInSystemStatus
        )
      "
      @edit="
        $emit('edit-component', {
          component: 'CompactUserAndAddressFormComponent',
          location: 'ContractDetails',
          properties: { formName: 'invoiceRecipient' },
        })
      "
    >
      <div v-html="presentableFeedInSystem.alternateInvoiceRecipient"></div>
    </RowItem>
    <!-- abweichender Lieferadresse -->
    <RowItem
      class="row-item"
      field="updatePVAlternateDeliveryAddress"
      :title="$t('feed_in_system_details.alternate_delivery_address')"
      :editable="
        !disqualifiedStatusesForAlternateRecipientAndAlternateDeliveryAddress.includes(
          this.feedInSystemStatus
        )
      "
      @edit="
        $emit('edit-component', {
          component: 'CompactUserAndAddressFormComponent',
          location: 'ContractDetails',
          properties: { formName: 'deliveryAddress' },
        })
      "
    >
      <div v-html="presentableFeedInSystem.alternateDeliveryAddress"></div>
    </RowItem>
    <!-- Einspeisevergütung -->
    <RowItem
      v-if="!isPVAnfrage"
      class="row-item"
      field="updatePVFeedInCompensation"
      :title="$t('feed_in_system_details.feed_in_compensation')"
      :editable="true"
      @edit="
        $emit('edit-component', {
          component: 'FeedInCompensationComponent',
          location: 'ContractDetails',
        })
      "
    >
      <div v-html="presentableFeedInSystem.feedInCompensation || '-'"></div>
    </RowItem>
    <!-- Vergütungssatz -->
    <!--     <RowItem
      class="row-item"
      :title="$t('feed_in_system_details.compensation_rate')"
      :editable="false"
      v-if="!isPVAnfrage"
    >
      TODO: get compensation_rate (i.e. 8,2 Cent pro kWh)
    </RowItem> -->
    <!-- Finanzwirtschaftliche Daten -->
    <RowItem
      v-if="!isPVAnfrage"
      class="row-item"
      field="updateFinanceData"
      :title="$t('feed_in_system_details.finance_data')"
      :editable="true"
      @edit="
        $emit('edit-component', {
          component: 'FinanceDataComponent',
          location: 'ContractDetails',
          properties: feedInSystem,
        })
      "
    >
      <div v-html="presentableFeedInSystem.financeData || '-'"></div>
    </RowItem>
    <!-- Marktstammdatenregister -->
    <RowItem
      v-if="!isPVAnfrage"
      class="row-item"
      field="updatePVMaStRData"
      :title="$t('market_data_register.title')"
      :editable="true"
      @edit="
        $emit('edit-component', {
          component: 'MarketDataRegisterComponent',
          location: 'ContractDetails',
          properties: feedInSystem,
        })
      "
    >
      <div>
        <div v-html="presentableFeedInSystem.maStR || '-'"></div>
      </div>
    </RowItem>
    <!-- Elektrofachbetrieb -->
    <RowItem
      :key="presentableFeedInSystem.plantConstructor"
      class="row-item"
      field="updatePVElectricInstallerCompany"
      :title="$t('feed_in_system_details.plant_constructor')"
      :editable="true"
      @edit="
        $emit('edit-component', {
          component: 'ElectricInstallerCompaniesComponent',
          location: 'ContractDetails',
          properties: { allInstallerCompanies: fetchedInstallerCompanies },
        })
      "
    >
      {{
        presentableFeedInSystem.plantConstructor
          ? presentableFeedInSystem.plantConstructor
          : presentablePlantConstructor
      }}
    </RowItem>
    <!-- Zählernummer -->
    <RowItem
      class="row-item"
      field="updatePVCounterId"
      :withLineHeight="true"
      :optionalHeaderText="
        this.$t('feed_in_system_details.counter_number_title')
      "
      :title="counter_number_title"
      :editable="showCounterId"
      @edit="
        $emit('edit-component', {
          component: 'CounterAvailabilityComponent',
          location: 'ContractDetails',
          properties: feedInSystem,
        })
      "
    >
      <div
        v-if="feedInSystem.new_counter_data"
        v-html="presentableFeedInSystem.counterNumber"
      ></div>

      <div v-else style="display: flex; flex-direction: column" class="mt-1">
        <div style="display: flex; flex-direction: column; gap: 8px">
          <div
            v-for="(c, index) in feedInSystem.counter_id_data"
            :key="index"
            style="display: flex"
          >
            <span>
              {{ c.counter_id }}
            </span>
            <span v-if="c.two_way_measurement_built_in">{{
              `, ${$t('counter_availability_step.two_way_counter')}`
            }}</span>
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-top: 14px;
          "
          v-if="
            feedInSystem &&
            feedInSystem.counters_to_remove_data &&
            feedInSystem.counters_to_remove_data.length > 0
          "
        >
          <div
            v-for="c in feedInSystem.counters_to_remove_data"
            :key="c.counter_id"
          >
            {{ c.counter_id }}
          </div>
        </div>
      </div>
    </RowItem>
    <!-- Zählerdaten -->
    <div
      v-if="
        isPVInbetriebnahmeProtokoll &&
        this.feedInSystem.pvimplementationprotocol_inspection
      "
    >
      <RowItem
        v-for="(item, index) in transformedCounters"
        v-bind:key="index"
        class="row-item"
        field="updatePhotovoltaics"
        :title="buildLabels(item, index)"
        :editable="true"
        @edit="
          $emit('edit-component', {
            component: 'UpdateCounterDataComponent',
            location: 'ContractDetails',
            properties: {
              counter: item,
              indexOfCounter: index,
              allCounters:
                feedInSystem.pvimplementationprotocol_inspection.counters,
            },
          })
        "
      >
        <br /><br />
        <div v-for="(counterData, key) in item" :key="key">
          {{ formatCounterData(counterData, key) }}
        </div>
      </RowItem>
    </div>

    <!-- Inbetriebnahmetermin -->
    <RowItem
      last
      class="row-item"
      field="updatePVImplementationAppointment"
      :title="$t('feed_in_system_details.implementation_appointment')"
      :editable="true"
      @edit="
        $emit('edit-component', {
          component: 'PVAppointmentDialog',
          location: 'ContractDetails',
        })
      "
    >
      <div>
        {{ presentableFeedInSystem.implementationAppointment || '-' }}
      </div>
    </RowItem>
  </div>
</template>

<script>
import RowItem from './RowItem';
import { formatPVObject } from './transform_raw_data_to_pv_format';
import { formatCounterData } from '@/helpers/transformToPresentableData';
import InstallerCompaniesService from '../../services/InstallerCompaniesService';
import { PVJOURNEYS } from '@/helpers/constants';
import { FEED_IN_STATUSES } from '@/helpers/constants';

export default {
  name: 'ContractDetails',
  components: {
    RowItem,
  },
  props: {
    feedInSystem: Object,
  },
  data() {
    return {
      FEED_IN_STATUSES: FEED_IN_STATUSES,
      disqualifiedStatusesForAlternateRecipientAndAlternateDeliveryAddress: [
        FEED_IN_STATUSES.tech_feas?.value,
        FEED_IN_STATUSES.created?.value,
        FEED_IN_STATUSES.confirmed?.value,
      ],
      fetchedInstallerCompanies: [],
      presentablePlantConstructor: '',
      /* make function available in template */
      formatCounterData: formatCounterData,
    };
  },
  async mounted() {
    await this.fetchInstallerCompanies();
  },
  computed: {
    counter_number_title() {
      let rowTitle = '';
      if (!this.feedInSystem?.counter_id_data) return rowTitle;

      const nbrOfCounters = this.feedInSystem?.counter_id_data.length;
      for (let i = 0; i < nbrOfCounters; i++) {
        rowTitle += `Zähler ${i + 1}`;
        if (i < nbrOfCounters - 1) {
          rowTitle += '<br>';
        }
      }
      if (
        !this.feedInSystem.counters_to_remove_data ||
        this.feedInSystem.counters_to_remove_data.length === 0
      ) {
        return rowTitle;
      }
      // From this point on, we have counters to remove. Display it.

      let countersToRemove = 0;
      if (this.feedInSystem.counters_to_remove_data) {
        countersToRemove = this.feedInSystem.counters_to_remove_data.length;
      }

      const templateString = (n) => `Zähler${n} zum Ausbau`;
      let countersToRemoveTitleChunk = '<br>';
      for (let i = 0; i < countersToRemove; i++) {
        countersToRemoveTitleChunk += templateString(i + 1);
        if (i < countersToRemove - 1) {
          countersToRemoveTitleChunk += '<br> ';
        }
      }
      rowTitle += countersToRemoveTitleChunk;
      return rowTitle;
    },
    presentableFeedInSystem() {
      return formatPVObject(this.feedInSystem);
    },
    isPVAnfrage() {
      return this.feedInSystem.journey === PVJOURNEYS.pv;
    },
    isPVInbetriebnahmeProtokoll() {
      return this.feedInSystem.journey === PVJOURNEYS.pvip;
    },
    feedInSystemStatus() {
      return this.feedInSystem.status;
    },
    showCounterId() {
      return (
        this.feedInSystemStatus !== FEED_IN_STATUSES.fulfilled_conf.value &&
        this.feedInSystemStatus !== FEED_IN_STATUSES.transmitted.value
      );
    },
    transformedCounters() {
      return this.feedInSystem.pvimplementationprotocol_inspection.counters.map(
        (counter) => {
          if (counter.mount_type === 'DIN') {
            return {
              ...counter,
              mount_type: 'Hutschienen',
            };
          }
          return counter;
        }
      );
    },
  },
  methods: {
    buildLabels(item, index) {
      let label = `<span style="font-size: 18px;line-height:22px"><b>${this.$t(
        'feed_in_system_details.counter_data'
      )} ${
        this.feedInSystem.pvimplementationprotocol_inspection.counters.length >
        0
          ? index + 1
          : ''
      }</b></br>`;
      // eslint-disable-next-line no-unused-vars
      for (const [key, _] of Object.entries(item)) {
        label += `<br>${this.$t(`feed_in_system_details.${key}`)}`;
      }
      label += '</span>';
      return label;
    },
    async fetchInstallerCompanies() {
      try {
        const params = {
          zip: '',
          product: 'electricity',
        };
        const companies = await InstallerCompaniesService.getInstallerCompanies(
          params
        );
        this.fetchedInstallerCompanies = companies.getInstallerCompanies;
        if (!this.feedInSystem.installation_company_electricity) {
          this.fetchedInstallerCompanies.filter((company) => {
            if (
              company.Id ===
              this.feedInSystem.installation_company_electricity_id
            ) {
              this.presentablePlantConstructor = `${company.Firma}, ${company.Strasse} ${company.Hausnummer}, ${company.Plz} ${company.Ort}`;
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style scoped>
.row-item {
  margin-block: 1px;
}
</style>
